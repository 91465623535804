import { useRef, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  // Button,
  ClickAwayListener,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  // Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import "/node_modules/flag-icons/css/flag-icons.min.css";
// import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
// project import
import IconButton from "components/@extended/IconButton";
import Transitions from "components/@extended/Transitions";
import useConfig from "hooks/useConfig";

// assets
import { I18n, ThemeMode } from "types/config";
// import { FormattedMessage, useIntl } from "react-intl";

// ==============================|| HEADER CONTENT - LOCALIZATION ||============================== //

const Localization = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("md"));

  const { i18n, onChangeLocalization } = useConfig();

  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (lang: I18n) => {
    onChangeLocalization(lang);
    setOpen(false);
  };

  const iconBackColorOpen =
    theme.palette.mode === ThemeMode.DARK ? "grey.200" : "grey.300";
  const iconBackColor =
    theme.palette.mode === ThemeMode.DARK ? "background.default" : "grey.100";

  // const intl = useIntl();

  // const handleRedirect = () => {
  //   let redirectUrl = localStorage.getItem('redirectLogin') || '';
  //   let result = redirectUrl.replace(/"/g, '');
  //   window.location.assign(result);
  // };

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      {/* <Tooltip className="portal_align" title={intl.formatMessage({ id: "header_user_portal" })}>
        <Button
          className="BtnStyl"
          color="secondary"
          variant="contained"
          sx={{
            color: "text.primary",
            border: "1px solid #adbd2f",
            borderRadius: "0px",
            backgroundColor:
              theme.palette.mode === "light" ? "#fff" : "#1e1e1e", 
            marginRight: "10px",
            "&:hover": {
              backgroundColor:
                theme.palette.mode === "light" ? "#e0e0e0" : "rgb(103 102 102)",
            },
          }}
          onClick={handleRedirect}
          startIcon={
            <Box sx={{ display: "flex", alignItems: "center", gap: "2px" }}>
             
              <NavigateBeforeIcon />{" "}
              <FormattedMessage id="header_user_portal" />
               </Box>
          }
        ></Button>
      </Tooltip> */}

      <IconButton
        color="secondary"
        variant="light"
        sx={{
          color: "text.primary",
          bgcolor: open ? iconBackColorOpen : iconBackColor,
        }}
        aria-label="open localization"
        ref={anchorRef}
        aria-controls={open ? "localization-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        {i18n === "en" ? (
          <span className="fi fi-us"></span>
        ) : i18n === "gr" ? (
          <span className="fi fi-de"></span>
        ) : (
          <span className="fi fi-fr"></span>
        )}
      </IconButton>
      <Popper
        placement={matchesXs ? "bottom-start" : "bottom"}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [matchesXs ? 0 : 0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions
            type="grow"
            position={matchesXs ? "top-right" : "top"}
            in={open}
            {...TransitionProps}
          >
            <Paper sx={{ boxShadow: theme.customShadows.z1 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <List
                  component="nav"
                  sx={{
                    p: 0,
                    width: "100%",
                    minWidth: 200,
                    maxWidth: 290,
                    bgcolor: theme.palette.background.paper,
                    borderRadius: 0.5,
                    [theme.breakpoints.down("md")]: {
                      maxWidth: 250,
                    },
                  }}
                >
                  <ListItemButton
                    selected={i18n === "en"}
                    onClick={() => handleListItemClick("en")}
                  >
                    <ListItemText
                      primary={
                        <Grid container alignItems={"center"}>
                          <span
                            className="fi fi-us"
                            style={{ marginRight: "5px" }}
                          ></span>
                          <Typography color="textPrimary">English</Typography>
                        </Grid>
                      }
                    />
                  </ListItemButton>
                  <ListItemButton
                    selected={i18n === "gr"}
                    onClick={() => handleListItemClick("gr")}
                  >
                    <ListItemText
                      primary={
                        <Grid container alignItems={"center"}>
                          <span
                            className="fi fi-de"
                            style={{ marginRight: "5px" }}
                          ></span>
                          <Typography color="textPrimary">German</Typography>
                        </Grid>
                      }
                    />
                  </ListItemButton>
                  <ListItemButton
                    selected={i18n === "fr"}
                    onClick={() => handleListItemClick("fr")}
                  >
                    <ListItemText
                      primary={
                        <Grid container alignItems={"center"}>
                          <span
                            className="fi fi-fr"
                            style={{ marginRight: "5px" }}
                          ></span>
                          <Typography color="textPrimary">French</Typography>
                        </Grid>
                      }
                    />
                  </ListItemButton>
                  {/* <ListItemButton selected={i18n === 'ro'} onClick={() => handleListItemClick('ro')}>
                    <ListItemText
                      primary={
                        <Grid container>
                          <Typography color="textPrimary">Română</Typography>
                          <Typography variant="caption" color="textSecondary" sx={{ ml: '8px' }}>
                            (Romanian)
                          </Typography>
                        </Grid>
                      }
                    />
                  </ListItemButton> */}
                  {/* <ListItemButton selected={i18n === 'zh'} onClick={() => handleListItemClick('zh')}>
                    <ListItemText
                      primary={
                        <Grid container>
                          <Typography color="textPrimary">中国人</Typography>
                          <Typography variant="caption" color="textSecondary" sx={{ ml: '8px' }}>
                            (Chinese)
                          </Typography>
                        </Grid>
                      }
                    />
                  </ListItemButton> */}
                </List>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Localization;
